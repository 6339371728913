// Note: This module gets imported right at the start in index.js
// So everything here gets evaluated first thing on app load.

export const isProduction = process.env.NODE_ENV === "production";

export const isTestStore = window.location.search.includes("shop=gro-");

export const isLogRocketEnabled = isProduction && !isTestStore;

// Public identifier for Shopify apps.
export const SHOPIFY_API_KEY = isProduction
  ? "572d6fab3dd51f35d320ad2c73771b82"
  : "965208b28e03dc67ae007fe88fed7ca2";

export const APP_PROXY_PATH_PREFIX = isProduction ? "a/gro1" : "a/dev-gro1";

export const GRO_PLATFORM_BASE_URL = isProduction
  ? "https://api.gro-funnels.com"
  : "";

export const isDebug =
  !isProduction || window.location.search.includes("gro-debug=");

export const EMBEDDED_APP_HANDLE = isProduction
  ? "gro-funnels"
  : "gro-funnels-dev";

export const getQueryParamValue = (param) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(param);
};

export const allQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(queryParams.entries());
};

export const pathWithQueryParams = (path, params) => {
  if (Object.entries(params || []).length < 1) {
    return path;
  }

  return (
    path +
    "?" +
    Object.entries(params)
      .map(([k, v]) => `${k}=${v}`)
      .join("&")
  );
};

export const camelCaseToTitleString = (text) => {
  const ret = text.replace(/([A-Z])/g, " $1");
  return ret.charAt(0).toUpperCase() + ret.slice(1);
};

export const camelCaseToSnakeCase = (str) => {
  str = str[0].toLowerCase() + str.slice(1, str.length); // CamelCase -> camelCase;
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const hashString = (string) => {
  var hash = 0,
    i,
    chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const unicodeStrikeThrough = (string) => {
  return (
    string &&
    Array.from(string)
      .map((chr) => `${chr}\u0336`)
      .join("")
  );
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const stripTags = (str) => {
  const el = document.createElement("div");
  el.innerHTML = str;
  return el.innerText;
};

export const trimSlashes = (str) => {
  return str.replace(/^\/|\/$/g, "");
};

import styled from "styled-components";
import React from "react";

export const HorizontalGutter = ({ height }) => {
  height = height || "1rem";
  return (
    <div
      style={{
        width: "100%",
        height,
      }}
    ></div>
  );
};

export const NewLinesToBrs = ({ children }) => {
  if (typeof children !== "string") {
    throw new Error(`<NewLinesToBrs> children must be string`);
  }

  const pieces = children.split("\n");
  if (pieces.length < 2) {
    return pieces[0];
  }
  return (
    <>
      {pieces[0]}
      {pieces.slice(1).map((p, idx) => (
        <React.Fragment key={idx}>
          <br />
          {p}
        </React.Fragment>
      ))}
    </>
  );
};

export const ParentSizedCenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import _ from "lodash";
import {
  useLocation,
  useNavigate as useReactRouterNavigate,
} from "react-router-dom";
import { useNavigate as useShopifyAppBridgeNavigate } from "@shopify/app-bridge-react";
import { isShopifyEmbedded } from "@shopify/app-bridge-utils";

import { logDebug } from "./log";
import { embeddedAppRedirect } from "./shopify-app-bridge";
import { useEffect } from "react";

const stallSeconds = async (seconds) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const hardRedirectTo = async (href, newContext) => {
  if (isShopifyEmbedded()) {
    logDebug(
      `[Gro]: Embedded app detected. Redirecting via app bridge to ${href}`
    );
    embeddedAppRedirect(href, newContext);
  } else {
    logDebug(`[Gro]: Hard redirecting to ${href}`);
    if (newContext) {
      window.open(href, "_blank");
    } else {
      window.location.href = href;
    }
  }

  // Stall so nothing happens until the redirect is done.
  await stallSeconds(60);
};

export const useNavigate = () => {
  const location = useLocation();

  let appBridgeNavigate = null;
  if (isShopifyEmbedded()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    appBridgeNavigate = useShopifyAppBridgeNavigate();
  }

  const reactRouterNavigate = useReactRouterNavigate();

  const navigate = (path) => {
    if (!path.startsWith("/")) {
      const currentPath = _.trimEnd(location.pathname, "/");
      path = `${currentPath}/${path}`;
    }
    return isShopifyEmbedded()
      ? appBridgeNavigate(path)
      : reactRouterNavigate(path);
  };

  return navigate;
};

export const Redirect = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  });

  return <></>;
};

import { clearCached, getCached, setCached } from "./caching";

export default (function () {
  const ACCESS_TOKEN_STORAGE_KEY = "access-token";

  const setAppTeamUserAccessToken = (val) => {
    setAccessToken(val);
    setCached("is_team_user", true);
  };
  const isAppTeamUser = () => Boolean(getCached("is_team_user"));

  const setAccessToken = (val) => setCached(ACCESS_TOKEN_STORAGE_KEY, val);
  const getAccessToken = () => getCached(ACCESS_TOKEN_STORAGE_KEY);
  const clearAccessToken = () => clearCached(ACCESS_TOKEN_STORAGE_KEY);

  const isAuthenticated = () => Boolean(getAccessToken());

  const SESSION_OAUTH_SHOP = "oauth-shop";
  const setOauthShopHint = (shop) => setCached(SESSION_OAUTH_SHOP, shop);
  const getOauthShopHint = () => getCached(SESSION_OAUTH_SHOP);
  const clearOauthShopHint = () => clearCached(SESSION_OAUTH_SHOP);

  // Explicit logout initiated by user.
  const logout = () => {
    clearAccessToken();
    clearOauthShopHint();
  };

  return {
    getAccessToken,
    setAccessToken,
    clearAccessToken,
    setAppTeamUserAccessToken,
    isAppTeamUser,
    isAuthenticated,
    getOauthShopHint,
    setOauthShopHint,
    clearOauthShopHint,
    logout,
  };
})();

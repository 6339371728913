export const unixTsToAge = (ts) => {
  const tsNowMillis = new Date().getTime();

  const ageInSeconds = Math.floor(tsNowMillis / 1000 - ts);

  const periods = [
    ["years", 31536000],
    ["months", 2678400],
    ["days", 86400],
    ["hours", 3600],
    ["minutes", 60],
  ];

  let agePeriodCount = Math.floor(ageInSeconds);
  let agePeriodLabel = "seconds";

  for (let i = 0; i < periods.length; i++) {
    const [label, periodSeconds] = periods[i];
    const periodCount = ageInSeconds / periodSeconds;
    if (periodCount >= 1) {
      agePeriodCount = Math.floor(periodCount);
      agePeriodLabel = label;
      break;
    }
  }
  if (agePeriodCount === 1) {
    agePeriodLabel = agePeriodLabel.substring(0, agePeriodLabel.length - 1);
  }

  return `${agePeriodCount} ${agePeriodLabel}`;
};

export const tsNow = () => new Date().getTime() / 1000;

// Wrapper for components that get loaded in the beginning
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { DisplayText, Loading, Spinner } from "@shopify/polaris";
import { isShopifyEmbedded } from "@shopify/app-bridge-utils";

import { HorizontalGutter } from ".";

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export function Loader(props) {
  let content = <DisplayText size="medium">Loading...</DisplayText>;
  if (props.children) {
    if (typeof props.children === "string") {
      content = <DisplayText size="medium">{props.children}</DisplayText>;
    } else {
      content = props.children;
    }
  }

  return (
    <LoaderContainer>
      {isShopifyEmbedded() && !props.noTopLoader && <Loading />}
      <Spinner accessibilityLabel="Loading" size={props.size || "large"} />
      <HorizontalGutter height="1rem" />
      {content}
    </LoaderContainer>
  );
}

function LoadedComponent(Component) {
  if (!Component.loadData) {
    return Component;
  }

  console.assert(
    typeof Component.loadData === "function",
    "LoadedCompoent.loadData must be a function"
  );

  const WrappedComponent = React.memo(function (props) {
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState(null);

    useEffect(
      () => {
        setIsLoading(true);
        async function load() {
          const data = await Component.loadData({ ...props });
          console.assert(
            data !== null,
            "LoadedComponent.loadData must never return null"
          );
          setLoadedData(data);
          setIsLoading(false);
        }
        load();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (props?.watch || []).map((p) => props[p])
    );

    if (isLoading) {
      return <Loader />;
    }

    console.assert(loadedData !== null, "!isLoading and data not loaded");
    return <Component {...props} loadedData={loadedData} />;
  });
  WrappedComponent.displayName = `${Component.name}-LoadingWrapped`;
  return WrappedComponent;
}

export default LoadedComponent;

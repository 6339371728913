export const extractIntIdIfShopifyGId = (shopifyId) => {
  shopifyId = String(shopifyId);
  if (shopifyId.startsWith("gid://")) {
    return shopifyId.split("/").reverse()[0];
  } else if (isNaN(parseInt(shopifyId))) {
    throw new Error(`Invalid shopify id ${shopifyId}`);
  }
  return shopifyId;
};

export const isProductOutOfStock = (productData) => {
  if (!productData?.tracks_inventory) {
    return false;
  }

  const isVariantOutOfStock = (v) =>
    Number.isInteger(v.inventory_quantity) &&
    v.inventory_quantity < 1 &&
    v.inventory_policy === "deny";
  const hasStock = Boolean(
    productData.variants.find((v) => !isVariantOutOfStock(v))
  );
  return !hasStock;
};

import React, { Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame, Card, Button } from "@shopify/polaris";
import { Heading, WarningText } from "tools/components/polaris";

import { useCachedState } from "./tools/caching";
import { Loader } from "./tools/components/loaded-component";

const TeamUserAuth = React.lazy(() =>
  import("tools/components/team-user-auth")
);
const ConnectPage = React.lazy(() => import("./pages/connect"));
const MerchantApp = React.lazy(() => import("./merchant-app"));

const LargeScreensOnlyNotice = ({ onSnooze }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Card sectioned>
        <div>
          <Heading>Please Use a Larger Screen</Heading>
        </div>
        <div>
          <WarningText>
            While the Offers you Create will be Fully Mobile Friendly,
            <br />
            the admin app has been Designed for Larger Screens.
            <br />
            Please use your Laptop or Desktop Computer.
          </WarningText>
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          <Button plain onClick={onSnooze}>
            Continue Anyway
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default function App(props) {
  const isScreenLargeEnough = useMediaQuery({
    query: "(min-width: 875px)",
  });

  const [snoozedLargeScreenNotice, setSnoozedLargeScreenNotice] =
    useCachedState({
      defaultValue: false,
      cacheKey: `Gro.snoozedLargeScreenNotice`,
    });

  return (
    <div className="App">
      <AppProvider i18n={enTranslations}>
        <Frame onNavigationDismiss={() => {}}>
          <Suspense fallback={<Loader />}>
            {isScreenLargeEnough || snoozedLargeScreenNotice ? (
              <BrowserRouter>
                <Routes>
                  <Route exact path="/connect" element={<ConnectPage />} />
                  <Route exact path="/__ta__" element={<TeamUserAuth />} />
                  <Route path="*" element={<MerchantApp />} />
                </Routes>
              </BrowserRouter>
            ) : (
              <LargeScreensOnlyNotice
                onSnooze={() => setSnoozedLargeScreenNotice(true)}
              />
            )}
          </Suspense>
        </Frame>
      </AppProvider>
    </div>
  );
}

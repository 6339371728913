import { Text } from "@shopify/polaris";

export const Heading = ({ children, size }) => {
  size = size || "h6";

  const variant = {
    h1: "heading4xl",
    h2: "heading3xl",
    h3: "heading2xl",
    h4: "headingXl",
    h5: "headingLg",
    h6: "headingMd",
  }[size];

  if (!variant) {
    throw new Error(`Unsupported heading size: ${size}`);
  }

  return (
    <Text variant={variant} as={size}>
      {children}
    </Text>
  );
};

export const SubduedText = ({ children }) => {
  return (
    <Text variant="bodyMd" as="span" color="subdued">
      {children}
    </Text>
  );
};

export const WarningText = ({ children }) => {
  return (
    <Text variant="bodyMd" as="span" color="warning">
      {children}
    </Text>
  );
};

export const CriticalText = ({ children, variant }) => {
  return (
    <Text variant={variant || "bodyMd"} as="span" color="critical">
      {children}
    </Text>
  );
};

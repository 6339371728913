import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "@shopify/polaris/build/esm/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { isProduction, EMBEDDED_APP_HANDLE } from "./config";
import Auth from "tools/auth";
import { startShopOauth } from "api";
import { logInfo } from "tools/log";
import { initSupportTools } from "tools/support";
import { isShopifyEmbedded } from "@shopify/app-bridge-utils";
import {
  allQueryParams,
  getQueryParamValue,
  pathWithQueryParams,
} from "tools/url";
import { isLogRocketEnabled, supportToolsEnabled } from "./tools/support";
import { emitMarketingEvent, initMarketingTools } from "tools/marketing";

if (isProduction) {
  Sentry.init({
    dsn: "https://d2c6dbdd05b04133bd14cd0e40ac8923@o4504026259980288.ingest.sentry.io/4504026260832256",
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
// HACK: Handle auth redirect scenarios before the react app is mounted.
// The AppBridgeProvider does its own redirects outside our control after
// the app has been mounted.
// Note: This was introduced as part of using per-user access mode oauth
// for merchant app auth - to get a clear idea of who exactly in the merchant team came up.
const isEmbedded = isShopifyEmbedded();
const groAuthJwt = getQueryParamValue("gro_jwt");
const shopName = (getQueryParamValue("shop") || "").split(".")[0];
const currentAppPath = window.location.pathname;

if (!isEmbedded && groAuthJwt) {
  // If landed directly on app with a gro_jwt - redirect back to the embedded app.
  const embeddedAppUrl = pathWithQueryParams(
    `https://admin.shopify.com/store/${shopName}/apps/${EMBEDDED_APP_HANDLE}${currentAppPath}`,
    allQueryParams()
  );
  window.location.href = embeddedAppUrl;
} else if (
  shopName &&
  !Auth.isAuthenticated() &&
  !groAuthJwt &&
  !(
    currentAppPath.startsWith("/connect") ||
    currentAppPath.startsWith("/__ta__")
  )
) {
  // If there is no auth payload available and not on /connect, start shopify oauth flow
  // Note: The case of (!isAuthenticated && groAuthJwt) is handled with merchant auth intent in merchant-app/index.js
  logInfo(
    "[Gro]: Not authenticated and no auth token found: Starting Shop Oauth with post auth destination path",
    currentAppPath
  );
  // HACK: TODO(Raj): clean this up - needed to make billing flow work without 3rd party cookies.
  const queryParams = {};
  ["plan_request_id", "charge_id"].forEach((qp) => {
    const qpValue = getQueryParamValue(qp);
    if (qpValue) {
      queryParams[qp] = qpValue;
    }
  });

  const postAuthPath = pathWithQueryParams(currentAppPath, queryParams);
  startShopOauth(shopName, postAuthPath);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(console.log);

  if (shopName) {
    Auth.setOauthShopHint(shopName);
  }

  if (isLogRocketEnabled) {
    LogRocket.init("zodjsu/gro-prod");
  }

  if (supportToolsEnabled) {
    initSupportTools();
  }

  initMarketingTools();

  emitMarketingEvent({
    eventId: "app-opened",
    data: {
      app: "gro-funnels",
      shop: shopName,
      path: currentAppPath,
    },
  });
}
